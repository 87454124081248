import React from 'react'
import About from '../components/About/about'
import Header from '../components/Header/header'
import Footer from '../components/Footer/footer'
import Layout from '../components/layout'
import Helmet from 'react-helmet'

const AboutPage = () => (
    <Layout>
         <Helmet
          title="VIP CARS - Rent a Car | Luksuzna auta | Centrala u Sarajevu"
          meta={[
            { name: 'description', content: 'VIP Cars - Rent a Car | Preuzmite Vaše luksuzno vozilo (auto) na bilo kojoj lokaciji na području BiH, Srbije i Hrvatske.  T:+387 62 520 556.' },
          ]} >
        </Helmet>
        <div id="about">
    <div className="header-back"></div>
    <Header/>
    <section className="section about">
         <div className="container">
            <About/>
        </div>
   </section>
   <Footer/>
   </div>
   </Layout>
)

export default AboutPage
