import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css";
import image1 from "../../images/v-class-1.jpg";
import {a_couple, of_words,about_vip_cars_sarajevo, about_us_text1, about_us_text2, about_us_text3} from '../strings'

const About = ({ siteTitle }) => (
    <div>
        <div className="columns first-section">
            <div animateIn='zoomIn' animateOut='zoomOut' offset="0" delay="0" animateOnce="true" className="column is-5 content">
                <h2 className="title-big">{a_couple} <br />{of_words} <br /><span>{about_vip_cars_sarajevo}</span></h2>
            </div>
            <div animateIn='fadeIn' animateOut='fadeOut' offset="0" delay="0" animateOnce="true" className="column is-7 image" > 
            <img src={image1} alt="VIP Cars V Class" className="mobile" />
            </div>
        </div>

        <div animateIn='fadeIn' animateOut='fadeOut' offset="0" delay="0" animateOnce="true" className="second-section">
            <p>{about_us_text1}</p>
            <p>{about_us_text2}</p>
            <p> {about_us_text3}</p>
        </div>
    </div>
)


export default About
